<template>
  <div class="fixed top-0 left-0 w-full h-full overflow-hidden -z-10">
    <img
      :src="
        isDesktop
          ? require('/assets/images/background-full.gif')
          : require('/assets/images/background-mobile.gif')
      "
      alt="background"
      class="h-full w-full object-cover"
    />
  </div>
  <div
    class="fixed top-0 left-0 h-full w-full flex justify-center items-center"
  >
    <p
      class="text-center font-['Cluster-Glitch'] blur-[0.5px] text-[30px] md:text-[50px] text-white drop-shadow-[3px_10px_5px_crimson] px-10"
    >
      Will you trade humanity for technology
      <span class="font-['Lcd-Dot'] blur-[0.5px]">?</span>
    </p>
  </div>
</template>

<script>
import { onMounted, ref } from 'vue';
export default {
  setup() {
    const isDesktop = ref(false);

    onMounted(() => {
      isDesktop.value =
        !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        );
    });

    return {
      isDesktop,
    };
  },
};
</script>

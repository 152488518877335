import { createRouter, createWebHistory } from 'vue-router';
import App from './App.vue';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: '/', component: App },
    // { path: '/:notFound(.*)', redirect: '/' },
    { path: '/:notFound(.*)', component: App },
  ],
});

export default router;
